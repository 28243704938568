<template>
  <div>
    <el-row>
      <el-col :span="1">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="22">
        <div class="grid-content">
          <div id="msdetailcompnent" v-if='metaboliteData.MetaboliteId !== undefined'>
            <!-- 写质谱信息 -->
            <div class="feature_id">
              <h2>
                MS ID: {{ metaboliteData.MsInfo[0].msID }}
              </h2>
              <el-row>
                <el-descriptions class="margin-top" :column="12" :size="medium" border>
                  <el-descriptions-item span="2" :min-width="80">
                    <template #label>
                      Q1 (Parent Ion Mass):
                    </template>
                    {{ metaboliteData.MsInfo[0].q1 }}
                  </el-descriptions-item>

                  <!-- <el-descriptions-item span="2">
                    <template #label>
                      origin:
                    </template>
                    {{ metaboliteData.MsInfo[0].origin }}
                  </el-descriptions-item> -->
                  <el-descriptions-item span="2" v-show="metaboliteData.MsInfo[0].energy" :min-width="80">
                    <template #label>
                      Energy:
                    </template>
                    {{ metaboliteData.MsInfo[0].energy }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2" v-show="metaboliteData.MsInfo[0].adult" :min-width="80">
                    <template #label>
                      Adult:
                    </template>
                    {{ metaboliteData.MsInfo[0].adult }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2" v-show="metaboliteData.MsInfo[0].mslevel" :min-width="80">
                    <template #label>
                      MS level:
                    </template>
                    {{ metaboliteData.MsInfo[0].mslevel }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2" v-show="metaboliteData.MsInfo[0].ion" :min-width="80">
                    <template #label>
                      Ion:
                    </template>
                    {{ metaboliteData.MsInfo[0].ion }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2" v-show="metaboliteData.MsInfo[0].instrument" :min-width="80">
                    <template #label>
                      Instrument:
                    </template>
                    {{ metaboliteData.MsInfo[0].instrument }}
                  </el-descriptions-item>


                </el-descriptions>
              </el-row>
            </div>
            <!-- 写名称结构 -->

            <div class="meta_id">
              <h2>
                PMhub ID: {{ metaboliteData.MetaboliteId[0].metabolite }}
              </h2>
              <el-row>
                <el-descriptions class="margin-top" :column="10" :size="medium" border>
                  <el-descriptions-item span="4" :min-width="80">
                    <template #label>
                      Name:
                    </template>
                    {{ metaboliteData.MetaboliteId[0].name }}
                  </el-descriptions-item>

                  <el-descriptions-item span="2" :min-width="80">
                    <template #label>
                      Formula:
                    </template>
                    {{ metaboliteData.MetaboliteId[0].molecular }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2" :min-width="80">
                    <template #label>
                      Average Mass:
                    </template>
                    {{ metaboliteData.MetaboliteId[0].molWt }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2" :min-width="80">
                    <template #label>
                      Monoisotopic Mass:
                    </template>
                    {{ metaboliteData.MetaboliteId[0].exactMolWt }}
                  </el-descriptions-item>
                  <el-descriptions-item span="2" :min-width="80">
                    <template #label>
                      PubChem:
                    </template>
                    <a :href="'https://pubchem.ncbi.nlm.nih.gov/compound/'+metaboliteData.MetaboliteId[0].pubchemId">
                      {{metaboliteData.MetaboliteId[0].pubchemId}}
                    </a>
                  </el-descriptions-item>
                  <el-descriptions-item span="2" :min-width="80">
                    <template #label>
                      KNApSAcK:
                    </template>
                    <a
                      :href="'http://www.knapsackfamily.com/knapsack_core/information.php?word='+metaboliteData.MetaboliteId[0].knapsackId">
                      {{metaboliteData.MetaboliteId[0].knapsackId}}
                    </a>
                  </el-descriptions-item>
                  <el-descriptions-item span="2" :min-width="80">
                    <template #label>
                      KEGG:
                    </template>
                    <a :href="'https://www.genome.jp/entry/cpd:'+metaboliteData.MetaboliteId[0].keggId">
                      {{metaboliteData.MetaboliteId[0].keggId}}
                    </a>
                  </el-descriptions-item>
                  <el-descriptions-item span="2" :min-width="80">
                    <template #label>
                      PMN:
                    </template>
                    <a :href="'https://pmn.plantcyc.org/compound?orgid=PLANT&id='+metaboliteData.MetaboliteId[0].pmnId">
                      {{metaboliteData.MetaboliteId[0].pmnId}}
                    </a>
                  </el-descriptions-item>
                  <el-descriptions-item span="2" :min-width="80">
                    <template #label>
                      HMDB:
                    </template>
                    <a :href="'https://hmdb.ca/metabolites/'+metaboliteData.MetaboliteId[0].hmdbId">
                      {{metaboliteData.MetaboliteId[0].hmdbId}}
                    </a>
                  </el-descriptions-item>
                  <el-descriptions-item span="4" :min-width="80">
                    <template #label>
                      SMILES:
                    </template>
                    {{ metaboliteData.MetaboliteId[0].smiles }}
                  </el-descriptions-item>
                  <el-descriptions-item span="6" :min-width="80">
                    <template #label>
                      Structure:
                    </template>
                    <div class="meta_structure" style="width: 200px;">
                      <!-- <img :src="getSvg(metaboliteData.MetaboliteId[0].svg)" style="width:100%"> -->
                      <!-- <canvas id="draw2d"></canvas> -->
                      <div id="draw2d"></div>
                    </div>
                  </el-descriptions-item>
                </el-descriptions>
              </el-row>
            </div>
            <!-- 画质谱图 -->
            <div class="ms_plot">
              <h2>
                MS/MS spectra
              </h2>
              <div>
                <div id='featurePlot'></div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
  import request from '@/network/request'
  import common from '@/mymethods/common.js'
  import $ from 'jquery'
  import ChemDoodle from "@/mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import * as d3 from 'd3'
  let canvas
  let context1
  export default {
    name: 'MsDetailCompnent',
    data () {
      return {
        energyDict: {
          "Energy0": "Low energy",
          "Energy1": "Medium energy",
          "Energy2": "High energy",
        }
      }
    },
    props: {
      metaboliteData: {
        type: Object,
        default: {},
      },
      head: {
        type: String,
        default: '',
      }
    },

    methods: {
      getSvg (svgStr) {
        let blob = new Blob([svgStr], {
          type: 'image/svg+xml'
        });
        let blobStr = URL.createObjectURL(blob)
        return blobStr
      },
      drawLibraryData (dataDict) {
        var ms2 = []
        for (let i in dataDict) {
          ms2.push(dataDict[i])
        }
        ms2 = ms2.splice(4)
        var ms2plot = []
        for (let i in ms2) {
          if (ms2[i]) {
            ms2plot.push(ms2[i])
          }
        }
        common.drawMsData(ms2plot, "#featurePlot")
      },
      drawLibraryMsData (dataDict) {
        var msDict = []
        for (var key in dataDict) {
          if (key.search("^q") != -1) {
            if (dataDict[key]) {
              msDict.push(dataDict[key])
            }
          }
        }
        msDict.splice(1, 0, 50)

        var msDict2 = []
        for (var i = 0; i < msDict.length; i += 2) {
          msDict2.push({ "ms": msDict[i], "intensity": msDict[i + 1] })
        }
        var xlist = msDict2.map(x => x.ms)
        var ylist = msDict2.map(x => x.intensity)
        d3.select("#featurePlot").select("svg").remove()
        var svg = d3.select("#featurePlot").append('svg').attr("width", 1000).attr("height", 300).attr('transform', 'translate(100,0)')
        var g = svg.append('g').attr("width", svg.attr("width") * 0.8).attr("height", svg.attr("height") * 0.8).attr('transform', 'translate(30,20)')

        let scaleLinearX = d3.scaleLinear().domain([d3.min(xlist) - 10, d3.max(xlist) + 10]).range([0, g.attr("width")])
        let scaleLinearY = d3.scaleLinear().domain([d3.max(ylist), 0]).range([0, g.attr("height")])
        g.selectAll('text')
          .data(msDict2)
          .enter()
          .append('text')
          .attr('class', function (d) { return "ms_" + String(d.ms).replace('.', '_') })
          .attr('x', function (d, i) {
            return scaleLinearX(d.ms)
          })

          .attr('y', function (d, i) {
            return scaleLinearY(d.intensity);
          })
          .attr('fill', 'rgba(0, 0, 139, 0.2)')
          .text(function (d) {
            return d.ms;
          })

        g.selectAll('rect')
          .data(msDict2)
          .enter()
          .append('rect')
          .attr('x', function (d, i) {
            return scaleLinearX(d.ms)
          })
          .attr('y', function (d, i) {
            return scaleLinearY(d.intensity);
          })
          .attr('width', 3)
          .attr('height', function (d, i) {
            return g.attr("height") - scaleLinearY(d.intensity);
          })
          .attr('fill', 'pink')
          .on("mouseover", function (event, d) {
            d3.select('text.ms_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue")
            d3.select(this)
              .attr("fill", "steelblue");
          })
          // .delay(2000)
          .on("mouseout", function (event, d) {
            d3.select('text.ms_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", "rgba(0, 0, 139, 0.2)")
            d3.select(this)
              .transition()
              .duration(1500)
              .attr("fill", "pink");
          })


        var axisX = d3.axisBottom(scaleLinearX).ticks(5);//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
        var axisY = d3.axisLeft(scaleLinearY).ticks(5)
        g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .attr('transform', 'translate(0,' + g.attr("height") + ')')
          .call(axisX);
        g.append('g')//在原有分g矩形分组里 再加一个x轴分组
          .call(axisY);

      },
    },
    components: {

    },
    created () {
      // console.log(this.showif)
    },
    mounted () {
      window.vue = this

    },
    updated () {
      this.drawLibraryData(this.metaboliteData.MsDetail[0])
      // common.getStructure(this.metaboliteData.MetaboliteId[0].smiles, 'draw2d', { width: 300, height: 300 })
      common.drawMol(this.metaboliteData.MetaboliteId[0].mol, 'draw2d', { width: 300, height: 300 })
      if (this.metaboliteData.MsInfo[0].energy in this.energyDict) {
        this.metaboliteData.MsInfo[0].energy = this.energyDict[this.metaboliteData.MsInfo[0].energy]
      }
    },
    watch: {
      metaboliteData: {
        handler (val, olVal) {
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-descriptions {
    font-size: 18.72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .el-link {
    color: rgb(77, 9, 236);
    font-size: 18.72px;
  }

  el-text {
    font-size: 18.72px;
  }

  p {
    font-size: 18.72px;
  }

  .cell {
    font-size: 18.72px;
  }

  .el-table {
    font-size: 18.72px;
  }

  img {
    width: 100%;
    height: auto;
  }
</style>