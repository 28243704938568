<template>
  <div id="msdetail">
    <MyheadCompnent></MyheadCompnent>
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <MsDetailCompnent :metaboliteData='metaboliteData' :headT='head'></MsDetailCompnent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import MetaboliteBrowseCompnentSearch from '@/components/Browse/MetaboliteBrowseCompnentSearch.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MsDetailCompnent from '@/components/Browse/MsDetailCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  import request from '@/network/request'
  export default {
    name: 'MsDetail',
    data () {
      return {
        seachParams: {
          format: 'json',
          searchItem: '',
        },
        metaboliteData: '',
        head: "Requesting !",
      }
    },
    methods: {
      getSearchData () {
        request({
          url: 'ms/detail/',
          params: this.seachParams,
        }).then(res => {
          // console.log(res);
          this.metaboliteData = res
          this.head = "Ms Details"
        }).catch(err => {
          console.log(err);
        });
      }
    },
    components: {
      MetaboliteBrowseCompnentSearch,
      MyheadCompnent,
      MyfooterCompnent,
      MsDetailCompnent,
    },
    created () {
      this.seachParams.searchItem = this.$route.params
      this.getSearchData()
    },
    // 相同路由，参数不一样，路由不跳转，但是beforeRouteUpdate可以监控
    // beforeRouteUpdate (to, from, next) {
    //   this.seachParams.searchItem = to.params.searchItem
    //   this.getSearchData()
    //   next();
    // },
    updated () {
    },
  }
</script>

<style scoped lang="scss">
</style>